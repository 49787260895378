<template>
  <div class="button-group">
    <slot/>
  </div>
</template>

<style scoped>
  .button-group {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & :deep(.app-button) {
      &:not(:last-of-type) {
        border-right: 0;
      }
    }
  }
</style>
